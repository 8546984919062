/*
This can be replaced by Tailwind's "text-gray-500" (but won't parse that way):
--text-opacity: 1;
color: #718096;
color: rgba(113, 128, 150, var(--text-opacity));
 */

html {
    font-size: 14px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    @apply antialiased;
}

body {
    @apply dark:bg-gray-800 overflow-hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    -webkit-tap-highlight-color: transparent;
}

#root {
    @apply overflow-hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

@tailwind base;

::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
}

body, input, select, button, textarea {
    @apply text-gray-800 dark:text-gray-200;
    font-family: 'Inter', --apple-system, 'Helvetica Neue', Helvetica, Roboto, sans-serif;
    line-height: 1;
}

input {
    background: transparent;
}

select {
    background: transparent;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
}

* {
    box-sizing: border-box;
}

h1, .h1 {
    font-weight: 600;
    font-size: 2.25rem;
    @apply text-gray-900 dark:text-white tracking-tighter;
}

h2, .h2 {
    font-weight: 600;
    font-size: 2rem;
    @apply text-gray-900 dark:text-white tracking-tight;
}

h3, .h3 {
    font-weight: 600;
    font-size: 1.75rem;
    letter-spacing: -0.4px;
    @apply text-gray-900 dark:text-white;
}

h4, .h4 {
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: -0.3px;
    @apply text-gray-900 dark:text-white;
}

h5, .h5 {
    font-weight: 500;
    font-size: 1rem;
    @apply text-gray-900 dark:text-white;
}

@tailwind components;
@tailwind utilities;

h6, .h6, .fc-col-header-cell, .fc-timegrid-slot {
    @apply text-sm font-medium text-gray-500 dark:text-gray-400;
}

.border-light {
    @apply border-gray-100 dark:border-gray-700 !important;
}

.border-light-dark {
    @apply border-gray-100 dark:border-gray-750 !important;
}

.p {
    @apply px-3;
}
h6.p {
    @apply pt-4 pb-2;
}
h6.pf {
    @apply pt-2 pb-2 px-3;
}

.sub {
    @apply text-sm leading-normal text-gray-500 dark:text-gray-400;
}

ul, ol {
    margin-left: 24px;
}

ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}

p, ol, ul {
    @apply leading-relaxed;
}

.truncate {
    line-height: 1.4;
}

.Streamline_Icon svg path[fill='#000000'] {
    fill: currentColor;
}

.link {
    @apply border-b border-gray-200 cursor-pointer pb-px dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600;
}

.link-orange {
    @apply border-b border-orange-300 dark:border-orange-700 cursor-pointer;
}

.two-pages {
    @apply h-full grid items-start overflow-hidden;
    grid-template-columns: 340px 1fr;
}

@media (min-width: 1536px) {
    .two-pages {
        grid-template-columns: 400px 1fr;
    }
}

.two-pages-sm {
    @apply h-full grid items-start overflow-hidden;
    grid-template-columns: 260px 1fr;
}

a {
    text-decoration: inherit;
}

.fc-timegrid-slot {
    @apply ml-20;
}

div.fcx-no-head .fc-scrollgrid thead {
    display: none;
}

.divider {
    @apply h-8 border-r dark:border-gray-700 pr-2 mr-2;
}

.pika-title {
    padding: 0 6px !important;
}

.pika-single, .pika-label {
    @apply text-gray-800 !important;
    font-family: 'Inter', --apple-system, 'Helvetica Neue', Helvetica, Roboto, sans-serif !important;
}

.pika-table thead tr th abbr {
    @apply tracking-wider uppercase text-xs font-medium;
}

.pika-single, .pika-label {
    @apply tabular;
}

.pika-single, .pika-button, .pika-label {
    background: transparent !important;
}

.pika-single {
    border: none !important;
}

.pika-table abbr[title] {
    cursor: default !important;
    text-decoration: none !important;
}

.is-today .pika-button {
    @apply text-red-500 bg-red-100 !important;
}

.is-today .pika-button:hover {
    @apply text-red-500 bg-red-200 !important;
}

.pika-prev, .pika-next {
    background-size: 14px 14px !important;
    padding: 0 2px !important;
    opacity: 1 !important;
    text-indent: -9999px !important;
    width: 25px !important;
    @apply bg-center stroke-current text-gray-800 dark:text-white !important;
}

.pika-button {
    @apply text-center rounded-lg text-gray-800 !important;
}

.pika-button:hover {
    @apply bg-gray-100 !important;
}

.pika-label {
    padding: 5px 2px !important;
    @apply font-medium text-sm !important;
}

.sortableHelper {z-index: 9999}

img {
    display: block;
    width: 100%;
}

.loader {
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    padding: 0 4px 0 2.5rem !important;
}

.tr {
    @apply transition-all duration-150 origin-center;
    -webkit-backface-visibility: hidden;
}

.z0 {
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.border:not(.border-light):not(.border-light-dark):not(.border-transparent),
.border-t:not(.border-light):not(.border-light-dark):not(.border-transparent),
.border-b:not(.border-light):not(.border-light-dark):not(.border-transparent),
.border-l:not(.border-light):not(.border-light-dark):not(.border-transparent),
.border-r:not(.border-light):not(.border-light-dark):not(.border-transparent) {
    @apply border-gray-200 dark:border-gray-700 !important;
}

.pushable, .pushable-sm {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    transition-duration: 150ms;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.pushable-sm:active {
    --transform-scale-x: .975;
    --transform-scale-y: .975;
}

.pushable:active {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
}

.tabular {
    font-variant-numeric: tabular-nums;
    -moz-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.dropdown-tip-border {
    border-bottom-color: #E3E8EF;
    border-top-color: #E3E8EF;
}
.dropdown-tip-bg {
    border-bottom-color: #FFFFFF;
    border-top-color: #FFFFFF;
}

.fc-theme-standard td, .fc-theme-standard th {
    @apply border-gray-100 dark:border-gray-700 !important;
}
.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}
.fc-col-header-cell {
    @apply pb-2 border-0 !important;
}
.fc-timegrid-axis {
    @apply border-0 !important;
}
a.fc-daygrid-event:not([href]) {
    @apply cursor-default;
}
.fc-daygrid-event-harness {
    @apply leading-snug !important;
}
.fc-daygrid-dot-event {
    @apply p-1 !important;
}
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
    background: #F7FAFC !important;
}
.fc-daygrid-dot-event:not([href]):hover, .fc-daygrid-dot-event.fc-event-mirror:not([href]) {
    background: none !important;
}
.fc .fc-daygrid-day.fc-day-today {
    background: transparent !important;
}
.fc-event-main-frame {
    @apply p-1 leading-snug;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    @apply text-white bg-red-500 rounded !important;
}
.fc {
    @apply select-none !important;
}
.fc-daygrid-day-number {
    @apply m-1;
    cursor: default !important;
}
.fc-scroller {
    @apply border-b border-gray-100 dark:border-gray-700;
}
.fc-event-title {
    @apply font-medium;
}
.fc-scroller {
    overflow: hidden !important;
}
.fc-day-today {
    background: transparent !important;
}
.fc-day-today .fc-col-header-cell-cushion {
    @apply text-red-500 font-semibold border-red-500 !important;
}
.fc .fc-timegrid-slot-label {
    vertical-align: top !important;
    border: none !important;
}
.fc-timegrid-slot-label-frame {
    margin-top: -5px !important;
}
.fc-timegrid-slots tbody tr:first-child .fc-timegrid-slot-label-frame {
    visibility: hidden !important;
}
.fc-timegrid-now-indicator-arrow {
    border-left-color: #F56565 !important;
    border-right-color: #F56565 !important;
}
.fc-timegrid-now-indicator-line {
    border-color: #F56565 !important;
}

.animate-left {
    animation: left 0.15s ease-in-out;
}

@keyframes left {
    0% {opacity: 0; position: relative; left: 10px}
    100% {opacity: 1; position: relative; left: 0}
}

.animate-fade-in {
    animation: fade-in 0.18s ease-in-out;
}

@keyframes fade-in {
    0% {opacity: 0}
    100% {opacity: 1}
}

.animate-open {
    animation: open 0.18s ease-in-out;
}

@keyframes open {
    0% {opacity: 0; transform: scale(0.95)}
    100% {opacity: 1; transform: scale(1)}
}

.animate-pop {
    animation: pop 0.18s ease-in-out;
}

@keyframes pop {
    0% {opacity: 0; transform: scale(0.95)}
    80% {opacity: 1; transform: scale(1.006)}
    100% {opacity: 1; transform: scale(1)}
}

.animate-dropdown-below {
    animation: dropdown-below 0.18s ease-in-out;
}

@keyframes dropdown-below {
    0% {opacity: 0; margin-top: calc(1.5rem - 3px); transform: scale(0.98)}
    100% {opacity: 1; margin-top: 1.5rem; transform: scale(1)}
}

.animate-dropdown-below-input {
    animation: dropdown-below-input 0.18s ease-in-out;
}

@keyframes dropdown-below-input {
    0% {opacity: 0; margin-top: calc(0.9rem - 3px); transform: scale(0.98)}
    100% {opacity: 1; margin-top: 0.9rem; transform: scale(1)}
}

.animate-dropdown-above {
    animation: dropdown-above 0.18s ease-in-out;
}

@keyframes dropdown-above {
    0% {opacity: 0; bottom: calc(4rem - 3px); transform: scale(0.98)}
    100% {opacity: 1; bottom: 4rem; transform: scale(1)}
}

.animate-up {
    animation: animate-up 0.18s ease-in-out;
}

@keyframes animate-up {
    0% {opacity: 0; top: 10px; position: relative;}
    100% {opacity: 1; top: 0; position: relative;}
}

.animate-down {
    animation: animate-down 0.18s ease-in-out;
}

@keyframes animate-down {
    0% {opacity: 0; top: -10px; position: relative;}
    100% {opacity: 1; top: 0; position: relative;}
}

.animate-hero1 {
    animation: animate-hero1 1.4s ease-in-out;
}

@keyframes animate-hero1 {
    0% {opacity: 0; margin-top: 10px;}
    70% {opacity: 0; margin-top: 10px;}
    100% {opacity: 1; margin-top: 0;}
}

.animate-hero2 {
    animation: animate-hero2 2.4s ease-in-out;
}

@keyframes animate-hero2 {
    0% {opacity: 0; margin-top: 10px;}
    83% {opacity: 0; margin-top: 10px;}
    100% {opacity: 1; margin-top: 0;}
}

.animate-hero3 {
    animation: animate-hero3 3.4s ease-in-out;
}

@keyframes animate-hero3 {
    0% {opacity: 0; margin-top: 10px;}
    88% {opacity: 0; margin-top: 10px;}
    100% {opacity: 1; margin-top: 0;}
}

.no-br br {
    display: none;
}

.animate-dropdown-above-input {
    animation: dropdown-above-input 0.18s ease-in-out;
}

@keyframes dropdown-above-input {
    0% {opacity: 0; bottom: calc(3.2rem - 3px); transform: scale(0.98)}
    100% {opacity: 1; bottom: 3.2rem; transform: scale(1)}
}

.animate-open-top {
    animation: open-top 0.2s ease-in-out;
}

@keyframes open-top {
    0% {opacity: 0; top: -25px; transform: scale(0.95)}
    100% {opacity: 1; top: 0; transform: scale(1)}
}

.animate-panel {
    animation: panel 0.2s ease-in-out;
}

@keyframes panel {
    0% {opacity: 0; margin-top: -25px; transform: scale(0.95)}
    100% {opacity: 1; margin-top: 0; transform: scale(1)}
}

.firebase-emulator-warning { display: none; }

.animate-open-bottom {
    animation: open-bottom 0.2s ease-in-out;
}

@keyframes open-bottom {
    0% {opacity: 0; top: 15px; transform: scale(0.95)}
    100% {opacity: 1; top: 0; transform: scale(1)}
}

.selection-area {
    background: rgba(212,212,216,0.1);
    border: 1px solid rgb(228,228,231);
    z-index: 100;
    @apply rounded-sm;
}

html.dark .selection-area {
    background: rgba(82,82,91,0.1);
    border: 1px solid rgb(82,82,91);
}

.demobox {
    background: radial-gradient(rgba(224,231,255,1) 0%, rgba(224,231,255,1) 30%, rgba(224,231,255,0) 70%);
}

::selection {
    background: rgba(0,0,0,0.1);
}

@media (max-width: 500px) {
    .h-screen {
        height: 100vh;
        height: -webkit-fill-available;
    }
}

/* DARK MODE */

.dark .demobox {
    background: radial-gradient(rgba(49,46,129,1) 0%, rgba(49,46,129,1) 30%, rgba(49,46,129,0) 70%);
}
html.dark .dropdown-tip-border {
    border-bottom-color: #334155; /* gray-700 */
    border-top-color: #334155;
}

html.dark .dropdown-tip-bg {
    border-bottom-color: #1E293B; /* gray-800 */
    border-top-color: #1E293B;
}
html.dark .text-gray-500 {
    --text-opacity: 1;
    color: #A0AEC0;
    color: rgba(160, 174, 192, var(--text-opacity));
}
html.dark .fc-daygrid-dot-event:hover, html.dark .fc-daygrid-dot-event.fc-event-mirror {
    background: #4A5568 !important;
}
html.dark .fc-theme-standard td, html.dark .fc-theme-standard th {
    @apply border-gray-700 !important;
}
html.dark .fc-timegrid-event-harness-inset .fc-timegrid-event, html.dark .fc-timegrid-event.fc-event-mirror {
    box-shadow: 0px 0px 0px 1.5px #1F2937 !important;
}
html.dark .hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #4A5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
}
html.dark .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    @apply bg-red-700 !important;
}
html.dark .is-today .pika-button {
     @apply text-red-300 bg-red-900 !important;
 }
html.dark .is-today .pika-button:hover {
    @apply text-red-300 bg-red-800 !important;
}
html.dark .pika-single, html.dark .pika-label {
    @apply text-white !important;
}
html.dark .pika-button {
    @apply text-gray-300 !important;
}
html.dark .pika-button:hover {
    @apply bg-gray-700 !important;
}