.link-color {
    @apply text-indigo-500 dark:text-indigo-400 cursor-pointer dark:hover:text-indigo-300 hover:text-indigo-600;
}

html.dark .dropdown-tip-border {
    border-bottom-color: #374151; /* gray-700 */
    border-top-color: #374151;
}

html.dark .dropdown-tip-bg {
    border-bottom-color: #1F2937; /* gray-800 */
    border-top-color: #1F2937;
}

.DraftEditor-root {
    @apply p-10 pr-6 pt-6 leading-loose whitespace-pre-wrap;
}

.DraftEditor-root .editor-block {
    @apply mt-3;
}