.onLinkHover:hover {
    color: var(--mainColor);
    border-bottom: 1px solid var(--mainColor);
}

.onLinkHover {
    cursor: pointer;
}

.onHoverRow:hover {
    background-color: rgba(241, 245, 249, 1);
}
